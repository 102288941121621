import { debounce } from "../helper/functions";

class SearchForm extends HTMLElement {
  constructor() {
    super();
    this.input = this.querySelector('input[type="text"]');
    this.resetButton = this.querySelector('button[type="reset"]');

    if (this.input && this.resetButton) {
      // Directly listen to the click event of the reset button
      this.resetButton.addEventListener('click', this.onFormReset.bind(this));

      this.input.addEventListener(
        'input',
        debounce((event) => {
          this.onChange(event);
        }, 300).bind(this)
      );
    }
  }

  onFormReset(event) {
    // Prevent any default behavior of the button
    event.preventDefault();

    // Explicitly set the input value to an empty string
    this.input.value = '';

    this.input.focus();
    this.toggleResetButton();

    console.log('Input value after reset:', this.input.value);
  }
  
}

customElements.define('search-form', SearchForm);
